import React, { useState } from "react";
import "./Styles/Styles.scss";
import SelectInputField from "../../../../../Components/InputFields/SelectInputField/SelectInputField";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";
import EntityInformation from "./EntityInformation/EntityInformation";
import RelatedPartyInformation from "./RelatedPartyInformation/RelatedPartyInformation";
import Modal from "react-bootstrap/Modal";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";

function AccountInformation({
  accountType,
  country,
  user,
  setAccountType,
  accountTypeError,
  setAccountTypeError,
  accountTypeOptions,
  accountCustodianInstitution,
  setAccountCustodianInstitution,
  accountCustodianInstitutionError,
  setAccountCustodianInstitutionError,
  accountCustodialNumber,
  setAccountCustodialNumber,
  accountCustodialNumberError,
  setAccountCustodialNumberError,
  jointAccountType,
  setJointAccountType,
  jointAccountTypeError,
  setJointAccountTypeError,
  jointAccountTypeOptions,
  entityName,
  setEntityName,
  entityNameError,
  setEntityNameError,
  entityAddressLine1,
  setEntityAddressLine1,
  entityAddressLine1Error,
  setEntityAddressLine1Error,
  entityAddressLine2,
  setEntityAddressLine2,
  entityAddressLine2Error,
  setEntityAddressLine2Error,
  entityCity,
  setEntityCity,
  entityCityError,
  setEntityCityError,
  entityState,
  setEntityState,
  entityStateError,
  setEntityStateError,
  entityPostalCode,
  setEntityPostalCode,
  entityPostalCodeError,
  setEntityPostalCodeError,
  entityTaxIDNumber,
  setEntityTaxIDNumber,
  entityTaxIDNumberError,
  setEntityTaxIDNumberError,
  entityPhoneNumber,
  setEntityPhoneNumber,
  entityPhoneNumberError,
  setEntityPhoneNumberError,
  entityStateOfIncorporation,
  setEntityStateOfIncorporation,
  entityStateOfIncorporationError,
  setEntityStateOfIncorporationError,
  jointHolderFirstName,
  setJointHolderFirstName,
  jointHolderFirstNameError,
  setJointHolderFirstNameError,
  jointHolderLastName,
  setJointHolderLastName,
  jointHolderLastNameError,
  setJointHolderLastNameError,
  jointHolderEmail,
  setJointHolderEmail,
  jointHolderEmailError,
  setJointHolderEmailError,
  jointHolderPhoneNumber,
  setJointHolderPhoneNumber,
  jointHolderPhoneNumberError,
  setJointHolderPhoneNumberError,
  jointHolderTaxIDNumber,
  setJointHolderTaxIDNumber,
  jointHolderTaxIDNumberError,
  setJointHolderTaxIDNumberError,
  jointHolderDOB,
  setJointHolderDOB,
  jointHolderDOBError,
  setJointHolderDOBError,
  jointHolderIsUSCitizen,
  setJointHolderIsUSCitizen,
  jointHolderIsUSCitizenError,
  setJointHolderIsUSCitizenError,
  jointHolderAddressLine1,
  setJointHolderAddressLine1,
  jointHolderAddressLine1Error,
  setJointHolderAddressLine1Error,
  jointHolderAddressLine2,
  setJointHolderAddressLine2,
  jointHolderAddressLine2Error,
  setJointHolderAddressLine2Error,
  jointHolderCity,
  setJointHolderCity,
  jointHolderCityError,
  setJointHolderCityError,
  jointHolderState,
  setJointHolderState,
  jointHolderStateError,
  setJointHolderStateError,
  jointHolderPostalCode,
  setJointHolderPostalCode,
  jointHolderPostalCodeError,
  setJointHolderPostalCodeError,
  hasTrustees,
  setHasTrustees,
  relatedPartyInfo,
  setRelatedPartyInfo,
  activeTab,
  setActiveTab,
  setHideButton,
  hideAlterButtons,
  addNewTab,
  handleRemoveFields,
  disabled,
  hasBeneficialOwners,
  setHasBeneficialOwners,
  beneficialOwnerInfo,
  setBeneficialOwnerInfo,
  beneficialOwnerActiveTab,
  setBeneficialOwnerActiveTab,
  handleBeneficialOwnerRemoveField,
  setHideBeneficialOwnerButton,
  hideAlterBeneficialOwnerButtons,
  addNewBeneficialOwnerTab,
  accountQualification,
  setAccountQualification,
  accountQualificationError,
  setAccountQualificationError,
  accountNotQualifiedError,
  setAccountNotQualifiedError,
  hasTrustManagementCompany = false,
  hasAlternateSignatory,
  setHasAlternateSignatory,
  hasAlternateSignatoryError,
  setHasAlternateSignatoryError,
  alternateSignatoryFullName,
  setAlternateSignatoryFullName,
  alternateSignatoryFullNameError,
  setAlternateSignatoryFullNameError,
  alternateSignatoryEmail,
  setAlternateSignatoryEmail,
  alternateSignatoryEmailError,
  setAlternateSignatoryEmailError,
  alternateSignatoryTitle,
  setAlternateSignatoryTitle,
  alternateSignatoryTitleError,
  setAlternateSignatoryTitleError,
  entityCountry,
  setEntityCountry,
  userRole,
  entityCountryError,
  setEntityCountryError
}) {
  const [showQPModal, setShowQPModal] = useState(false);

  const QPModal = () => (
    <Modal
      show={showQPModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowQPModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Qualified Purchaser
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="regulatory-info-text pe-5 pb-5">
          <b>Qualified Purchaser: </b>
          (51) (A) “Qualified purchaser” means— (i) any natural person
          (including any person who holds a joint, community property, or other
          similar shared ownership interest in an issuer that is excepted under
          section 80a–3(c)(7) of this title with that person’s qualified
          purchaser spouse) who owns not less than $5,000,000 in investments, as
          defined by the Commission; (ii) any company that owns not less than
          $5,000,000 in investments and that is owned directly or indirectly by
          or for 2 or more natural persons who are related as siblings or spouse
          (including former spouses), or direct lineal descendants by birth or
          adoption, spouses of such persons, the estates of such persons, or
          foundations, charitable organizations, or trusts established by or for
          the benefit of such persons; (iii) any trust that is not covered by
          clause (ii) and that was not formed for the specific purpose of
          acquiring the securities offered, as to which the trustee or other
          person authorized to make decisions with respect to the trust, and
          each settlor or other person who has contributed assets to the trust,
          is a person described in clause (i), (ii), or (iv); or (iv) any
          person, acting for its own account or the accounts of other qualified
          purchasers, who in the aggregate owns and invests on a discretionary
          basis, not less than $25,000,000 in investments. (B) The Commission
          may adopt such rules and regulations applicable to the persons and
          trusts specified in clauses (i) through (iv) of subparagraph (A) as it
          determines are necessary or appropriate in the public interest or for
          the protection of investors. (C) The term “qualified purchaser” does
          not include a company that, but for the exceptions provided for in
          paragraph (1) or (7) of section 80a–3(c) of this title , would be an
          investment company (hereafter in this paragraph referred to as an
          “excepted investment company”), unless all beneficial owners of its
          outstanding securities (other than short-term paper), determined in
          accordance with section 80a–3(c)(1)(A) of this title , that acquired
          such securities on or before April 30, 1996 (hereafter in this
          paragraph referred to as “pre-amendment beneficial owners”), and all
          pre-amendment beneficial owners of the outstanding securities (other
          than short-term paper) of any excepted investment company that,
          directly or indirectly, owns any outstanding securities of such
          excepted investment company, have consented to its treatment as a
          qualified purchaser. Unanimous consent of all trustees, directors, or
          general partners of a company or trust referred to in clause (ii) or
          (iii) of subparagraph (A) shall constitute consent for purposes of
          this subparagraph.
        </span>
      </Modal.Body>
      <Modal.Footer>
        <MuiButton
          buttonText={"Got it"}
          onClick={() => setShowQPModal(false)}
          size="large"
        />
      </Modal.Footer>
    </Modal>
  );

  const custodianList = [
    { value: "Pershing", label: "Pershing" },
    { value: "Schwab", label: "Schwab" },
    { value: "Fidelity", label: "Fidelity" },
    { value: "NorthWest Bank", label: "NorthWest Bank" },
  ];

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mt-3 mb-3">
        <h6>Account Information </h6>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div
          className={
            accountType === "IND" || accountType === ""
              ? "col pe-0"
              : " col pe-2"
          }
        >
          <SelectInputField
            value={accountType}
            setValue={setAccountType}
            valueError={accountTypeError}
            setValueError={setAccountTypeError}
            selectOptions={country && (country === "United States"|| (user && user.firmInfo && user.firmInfo.name === 'Sigma Advisors')) ? (accountTypeOptions.concat((user && user.firmInfo && user.firmInfo.name === 'Sigma Advisors') ? [{ value: "FE", label: "Foreign Entity" }] : [])) : [{ value: "IND", label: "Individual" }]}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-account-type-select"}
            label={"Account Type"}
          />
        </div>
        {accountType === "JOINT" && (
          <div className="col ps-2 pe-0">
            <SelectInputField
              value={jointAccountType}
              setValue={setJointAccountType}
              valueError={jointAccountTypeError}
              setValueError={setJointAccountTypeError}
              selectOptions={jointAccountTypeOptions}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"client-joint-account-type-select"}
              label={"Joint Account Type"}
            />
          </div>
        )}
        {accountType !== "" &&
          accountType !== "IND" &&
          accountType !== "JOINT" && (
            <div className="col ps-2 pe-0">
              <TextInputField
                value={entityName}
                setValue={setEntityName}
                valueError={entityNameError}
                setValueError={setEntityNameError}
                label={"Entity Name"}
                required={true}
                fullWidth={true}
                size={"small"}
                id={"client-entity-name-input"}
              />
            </div>
          )}
      </div>
      {userRole !== 'Self-Serve' && <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <SelectInputField
            value={accountCustodianInstitution}
            setValue={setAccountCustodianInstitution}
            valueError={accountCustodianInstitutionError}
            setValueError={setAccountCustodianInstitutionError}
            selectOptions={custodianList}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-account-custodian-type-select"}
            label={"Custodial Institution"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={accountCustodialNumber}
            setValue={setAccountCustodialNumber}
            valueError={accountCustodialNumberError}
            setValueError={setAccountCustodialNumberError}
            label={"Custodial Account #"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-account-custodial-number-input"}
          />
        </div>
      </div>}

      {accountType !== "IND" &&
        accountType !== "" &&
        accountType !== "JOINT" && (
          <EntityInformation
            accountType={accountType}
            entityAddressLine1={entityAddressLine1}
            setEntityAddressLine1={setEntityAddressLine1}
            entityAddressLine1Error={entityAddressLine1Error}
            setEntityAddressLine1Error={setEntityAddressLine1Error}
            entityAddressLine2={entityAddressLine2}
            setEntityAddressLine2={setEntityAddressLine2}
            entityAddressLine2Error={entityAddressLine2Error}
            setEntityAddressLine2Error={setEntityAddressLine2Error}
            entityCity={entityCity}
            setEntityCity={setEntityCity}
            entityCityError={entityCityError}
            setEntityCityError={setEntityCityError}
            entityState={entityState}
            setEntityState={setEntityState}
            entityStateError={entityStateError}
            setEntityStateError={setEntityStateError}
            entityPostalCode={entityPostalCode}
            setEntityPostalCode={setEntityPostalCode}
            entityPostalCodeError={entityPostalCodeError}
            setEntityPostalCodeError={setEntityPostalCodeError}
            entityTaxIDNumber={entityTaxIDNumber}
            setEntityTaxIDNumber={setEntityTaxIDNumber}
            entityTaxIDNumberError={entityTaxIDNumberError}
            setEntityTaxIDNumberError={setEntityTaxIDNumberError}
            entityPhoneNumber={entityPhoneNumber}
            setEntityPhoneNumber={setEntityPhoneNumber}
            entityPhoneNumberError={entityPhoneNumberError}
            setEntityPhoneNumberError={setEntityPhoneNumberError}
            entityStateOfIncorporation={entityStateOfIncorporation}
            setEntityStateOfIncorporation={setEntityStateOfIncorporation}
            entityStateOfIncorporationError={entityStateOfIncorporationError}
            setEntityStateOfIncorporationError={
              setEntityStateOfIncorporationError
            }
            hasAlternateSignatory={hasAlternateSignatory}
            setHasAlternateSignatory={setHasAlternateSignatory}
            hasAlternateSignatoryError={hasAlternateSignatoryError}
            setHasAlternateSignatoryError={setHasAlternateSignatoryError}
            alternateSignatoryFullName={alternateSignatoryFullName}
            setAlternateSignatoryFullName={setAlternateSignatoryFullName}
            alternateSignatoryFullNameError={alternateSignatoryFullNameError}
            setAlternateSignatoryFullNameError={
              setAlternateSignatoryFullNameError
            }
            alternateSignatoryEmail={alternateSignatoryEmail}
            setAlternateSignatoryEmail={setAlternateSignatoryEmail}
            alternateSignatoryEmailError={alternateSignatoryEmailError}
            setAlternateSignatoryEmailError={setAlternateSignatoryEmailError}
            alternateSignatoryTitle={alternateSignatoryTitle}
            setAlternateSignatoryTitle={setAlternateSignatoryTitle}
            alternateSignatoryTitleError={alternateSignatoryTitleError}
            setAlternateSignatoryTitleError={setAlternateSignatoryTitleError}
            entityCountry={entityCountry}
            setEntityCountry={setEntityCountry}
            entityCountryError={entityCountryError}
            setEntityCountryError={setEntityCountryError}

          />
        )}
      {accountType !== "IND" && accountType !== "" && (
        <RelatedPartyInformation
          accountType={accountType}
          jointHolderFirstName={jointHolderFirstName}
          setJointHolderFirstName={setJointHolderFirstName}
          jointHolderFirstNameError={jointHolderFirstNameError}
          setJointHolderFirstNameError={setJointHolderFirstNameError}
          jointHolderLastName={jointHolderLastName}
          setJointHolderLastName={setJointHolderLastName}
          jointHolderLastNameError={jointHolderLastNameError}
          setJointHolderLastNameError={setJointHolderLastNameError}
          jointHolderEmail={jointHolderEmail}
          setJointHolderEmail={setJointHolderEmail}
          jointHolderEmailError={jointHolderEmailError}
          setJointHolderEmailError={setJointHolderEmailError}
          jointHolderPhoneNumber={jointHolderPhoneNumber}
          setJointHolderPhoneNumber={setJointHolderPhoneNumber}
          jointHolderPhoneNumberError={jointHolderPhoneNumberError}
          setJointHolderPhoneNumberError={setJointHolderPhoneNumberError}
          jointHolderTaxIDNumber={jointHolderTaxIDNumber}
          setJointHolderTaxIDNumber={setJointHolderTaxIDNumber}
          jointHolderTaxIDNumberError={jointHolderTaxIDNumberError}
          setJointHolderTaxIDNumberError={setJointHolderTaxIDNumberError}
          jointHolderDOB={jointHolderDOB}
          setJointHolderDOB={setJointHolderDOB}
          jointHolderDOBError={jointHolderDOBError}
          setJointHolderDOBError={setJointHolderDOBError}
          jointHolderIsUSCitizen={jointHolderIsUSCitizen}
          setJointHolderIsUSCitizen={setJointHolderIsUSCitizen}
          jointHolderIsUSCitizenError={jointHolderIsUSCitizenError}
          setJointHolderIsUSCitizenError={setJointHolderIsUSCitizenError}
          jointHolderAddressLine1={jointHolderAddressLine1}
          setJointHolderAddressLine1={setJointHolderAddressLine1}
          jointHolderAddressLine1Error={jointHolderAddressLine1Error}
          setJointHolderAddressLine1Error={setJointHolderAddressLine1Error}
          jointHolderAddressLine2={jointHolderAddressLine2}
          setJointHolderAddressLine2={setJointHolderAddressLine2}
          jointHolderAddressLine2Error={jointHolderAddressLine2Error}
          setJointHolderAddressLine2Error={setJointHolderAddressLine2Error}
          jointHolderCity={jointHolderCity}
          setJointHolderCity={setJointHolderCity}
          jointHolderCityError={jointHolderCityError}
          setJointHolderCityError={setJointHolderCityError}
          jointHolderState={jointHolderState}
          setJointHolderState={setJointHolderState}
          jointHolderStateError={jointHolderStateError}
          setJointHolderStateError={setJointHolderStateError}
          jointHolderPostalCode={jointHolderPostalCode}
          setJointHolderPostalCode={setJointHolderPostalCode}
          jointHolderPostalCodeError={jointHolderPostalCodeError}
          setJointHolderPostalCodeError={setJointHolderPostalCodeError}
          hasTrustees={hasTrustees}
          setHasTrustees={setHasTrustees}
          relatedPartyInfo={relatedPartyInfo}
          setRelatedPartyInfo={setRelatedPartyInfo}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setHideButton={setHideButton}
          hideAlterButtons={hideAlterButtons}
          addNewTab={addNewTab}
          handleRemoveFields={handleRemoveFields}
          disabled={disabled}
          hasBeneficialOwners={hasBeneficialOwners}
          setHasBeneficialOwners={setHasBeneficialOwners}
          beneficialOwnerInfo={beneficialOwnerInfo}
          setBeneficialOwnerInfo={setBeneficialOwnerInfo}
          beneficialOwnerActiveTab={beneficialOwnerActiveTab}
          setBeneficialOwnerActiveTab={setBeneficialOwnerActiveTab}
          handleBeneficialOwnerRemoveField={handleBeneficialOwnerRemoveField}
          setHideBeneficialOwnerButton={setHideBeneficialOwnerButton}
          hideAlterBeneficialOwnerButtons={hideAlterBeneficialOwnerButtons}
          addNewBeneficialOwnerTab={addNewBeneficialOwnerTab}
          hasTrustManagementCompany={hasTrustManagementCompany}
        />
      )}
      <SnackBar
        snackbarState={accountQualificationError}
        setSnackbarState={setAccountQualificationError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"You must specify the account qualification status."}
      />
      <SnackBar
        snackbarState={accountNotQualifiedError}
        setSnackbarState={setAccountNotQualifiedError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          "Sorry, we only support qualified purchaser accounts at this time."
        }
      />
    </React.Fragment>
  );
}
export default AccountInformation;
